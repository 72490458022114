import store from '@public/js/store';
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    SharePage = async () => import(
        /* webpackChunkName: 'viral' */
        '../views/SharePage.vue'
    );

export default [
    { path: '/share', component: SharePage },
    // this is a user experience enhancement. it is technically redundant. it is not actually redundant in a psychological sense.
    // (aka/ex: why on earth am i hovering over a skip link and it says "send-card???" == bad experience for users)
    { path: '/send-card',  redirect: () => '/game' },
    { path: '/skip-card',  redirect: () => {
        store.commit('profile/updateProfile', { cardSkipped: true });
        return '/game';
    } },
];
